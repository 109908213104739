
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

import ListBlock from '@/components/detailBlocks/ListBlock.vue';
import DataTableHandler from '@/components/DataTable/DataTable';

@Component({
  name: 'OpenTaskListBlock',
  components: {
    ListBlock,
  },
})
export default class OpenTasksBlock extends Vue {
  @Prop({ required: true })
  private readonly blockId!: number;

  @Prop({ required: true })
  protected readonly navigationSlug!: string;

  @Prop({ required: true })
  protected readonly dossierTypeSlug!: string;

  @Prop({ required: true })
  protected readonly dossierId!: number;

  @Prop({ required: true })
  private readonly title!: string;

  @Prop()
  private readonly context: string | undefined;

  private dataTable = new DataTableHandler();
}
